<template>
  <div class="case">
    <div class="search-content">
      <el-input v-model="search" type="search" placeholder="搜索" @keyup.enter.native="getCases(search)">
        <el-button slot="append" icon="el-icon-search" @click="getCases(search)"></el-button>
      </el-input>
    </div>
    <div class="case-select">
      <button 
        v-for="(item, index) in category" 
        :key="index" 
        type="button" 
        :class="current == item ? 'caseactive' : ''" 
        @click="getCases(item)">
        {{ item }}
      </button>
    </div>
    <div class="case-row">
      <div class="case-col" v-for="item in lists" :key="item.Cid">
        <a :href="item.path" target="_blank">
          <!-- <img :src="item.cover" /> -->
          <el-image
            style="width: 100%; height: 130px;"
            :src="item.cover"
            fit="cover">
          </el-image>
          <h3>{{ item.CaseTitle }}</h3>
        </a>
      </div>
      <el-empty v-if="showsearch">
        <el-button type="primary" @click="search=''; current='商业消防'; getCases(current)">重新搜索</el-button>
      </el-empty>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      search: this.$route.query.q == undefined ? '' : this.$route.query.q,
      category: [],
      lists: [],
      current: '商业消防',
      showsearch: false
    }
  },

  mounted(){
    let that = this
    that.getCategory()
    if(that.$route.query.q != undefined){
      that.getCases(that.search)
    }else{
      if(that.$route.query.iid != undefined){
        let _iid = that.$route.query.iid
        that.current = that.activeCurrentCase(_iid * 1)
        console.log(that.current)
        that.getCases(that.current)
      } else{
        that.getCases(that.current)
      }
    }
  },

  methods: {
    /**
     * Get Category
     */
    getCategory: function(){
      let that = this
      let _query = new FormData()
      _query.append(
        'json', '{"id":""}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/Case/getCaseIndustry'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        let _res = response.data.value.replace('，', ',')
        let _res1 = _res.replace(' ', '')
        let _res2 = _res1.split(',')
        let _res3 = Array.from(new Set(_res2))
        that.category = _res3
      }).catch(function (error) {
        console.log(error)
      })
    },

    /**
     * Get Lists
     */
    getCases: function(casetitle){
      let that = this
      that.current = casetitle
      that.showsearch = false
      let _query = new FormData()
      _query.append(
        'json', '{"pagesize":100,"pageindex":1,"caseTitle":"","caseIndustry":"' + casetitle + '"}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/Case/getsuccessfulCase'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        response.data.value.forEach(item => {
          item.cover = item.documents[0].DocumentType == 'CaseCover' ? item.documents[0].DocumentPath : item.documents[1].DocumentPath
          item.path = item.documents[0].DocumentType == 'CaseFile' ? item.documents[0].DocumentPath : item.documents[1].DocumentPath
        })
        that.lists = response.data.value
        if(response.data.value.length == 0){
          that.showsearch = true
        }
      }).catch(function (error) {
        console.log(error)
      })
    },

    /**
     * 获取选中的行业案例
     */
    activeCurrentCase: function(iid){
      let _current = ''
      switch(iid){
        case 17:
          _current = '商业消防';
          break;
        case 18:
          _current = '电力市场';
          break;
        case 19:
          _current = '工业厂房';
          break;
        case 20:
          _current = '海事市场';
          break;
        case 22:
          _current = '矿业市场';
          break;
        case 23:
          _current = '商业空调';
          break;
        case 24:
          _current = '石油化工';
          break;
        case 25:
          _current = '水市场';
          break;
        case 26:
          _current = '基础设施';
          break;
        default:
          _current = '商业消防';
          break;
      }
      return _current
    }

  }
}
</script>
